<template>
  <validation-observer
    ref="passwordResetForm"
    #default="{invalid}"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="submitPasswordReset"
      >
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="new-password">{{ $t(`input_fields.new_password`) }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t(`input_fields.new_password`)"
            vid="password"
            :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required }"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="new-password"
                v-model="password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            <b-alert
              :show="errors.length > 0"
              variant="warning"
              class="mt-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>{{ $t('password_warning') }}</span>
              </div>
            </b-alert>
          </validation-provider>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group
          label-for="confirm-password"
          label="Confirm Password"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t(`input_fields.confirm_password`)"
            vid="password-confirm"
            :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required, confirmed: 'password' }"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="confirm-password"
                v-model="password_confirm"
                :type="passwordFieldTypeRetype"
                name="password-confirm"
                :state="errors.length > 0 ? false:null"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            <b-alert
              :show="errors.length > 0"
              variant="warning"
              class="mt-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>{{ $t('password_warning') }}</span>
              </div>
            </b-alert>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button
          class="mt-1"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
        >
          <span>{{ $t('buttons.submit') }}</span>
        </b-button>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BAlert,
  // BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import {
  heightFade,
} from '@core/directives/animations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  regex,
} from '@validations'
import {
  togglePasswordVisibility,
} from '@core/mixins/ui/forms'
// import {
//   getHomeRouteForLoggedInUser,
// } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { initialAbility } from '@/libs/acl/config'
import { mapActions } from 'vuex'

export default {
  components: {
    BAlert,
    // BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: null,
      password_confirm: null,
      required,
      regex,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      termsSelected: null,
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    submitPasswordReset() {
      this.$refs.passwordResetForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            const payload = {
              password: this.password,
              token: this.$route.query.token,
            }
            await this.changePassword(payload)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Your password was successfully reset. Please login with your new password',
              },
            }, 5000)
            this.$router.push({ name: 'auth-login', replace: true })
            /*
            const newUser = registerResponse.data
            await this.getUserProfile()
            console.log(newUser.ability)
            console.log(newUser)
            console.log(this.activeUser)
            this.$ability.update(newUser.ability || initialAbility)
            this.$router.replace(getHomeRouteForLoggedInUser(newUser.role.name, true)).then(() => {})
              .catch(error => {
                console.debug(`Redirect error after signup: ${error.message}`)
                // this.serverError = this.$t('errors.generic')
              })
              .finally(
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Your DenTyme Account was successfully created.',
                  },
                }),
              )
              */
          } catch (error) {
            console.debug(`Password Reset Error: ${error.message}`)
            if (error.response) {
              if (error.response.status < 500) {
                this.serverError = error.response.data.message
              } else {
                this.serverError = this.$t('errors.generic')
              }
            } else {
              this.serverError = this.$t('errors.generic')
            }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Could not reset your password. Please try again',
              },
            }, 5000)
          } finally {
            this.loading = false
          }
        }
      })
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
