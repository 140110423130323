<!-- eslint-disable vue/no-v-html -->
<template>
  <swiper
    class="swiper-paginations"
    :options="swiperOptions"
  >
    <swiper-slide
      v-for="(slide,index) in slideImages"
      :key="slide.img"
    >
      <div class="slide-container">
        <b-img
          fluid
          :src="slide.img"
        />
        <div class="slide-text mt-5 mb-3 text-center">
          <h1
            class="text-white"
            v-html="$t(`info_slider.slides[${index}].title`)"
          />
          <p
            class="text-white"
            v-html="$t(`info_slider.slides[${index}].description`)"
          />
        </div>
        <!-- <div class="swiper-lazy-preloader swiper-lazy-preloader-white" /> -->
      </div>
    </swiper-slide>

    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BImg,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /* eslint-disable global-require */
      slideImages: [
        { img: require('@/assets/images/svg/wallet.svg') },
        { img: require('@/assets/images/svg/metrics.svg') },
        { img: require('@/assets/images/svg/forms.svg') },
        { img: require('@/assets/images/svg/knowledge.svg') },
      ],
      /* eslint-disable global-require */
      swiperOptions: {
        lazy: false,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .slide-text{
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
  .slide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      max-height: 60%;
      max-width: 60%;
    }
  }
</style>

<style lang="scss">
  .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }
</style>
